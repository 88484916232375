<template>
  <ServiceComboFormPart
    :service-types="serviceTypes"
    :service-selections="serviceSelections"
    v-on="$listeners"
  />
</template>

<script>
import {
  windowFramesPlasticConsumerBE,
  windowFramesPlasticCorporateBE,
  windowFramesWoodConsumerBE,
  windowFramesWoodCorporateBE,
  windowFramesAluminiumConsumerBE,
  windowFramesAluminiumCorporateBE,
} from 'chimera/windowFrames/service'
import ServiceComboFormPart, {
  consumerKey,
  corporateKey,
} from 'chimera/all/themes/blueflow/components/form/part/service/ServiceComboFormPart'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceComboFormPart,
  },
  /**
   * @returns {object}
   */
  data() {
    return {
      serviceTypes: [
        new Selectable('Woning', 'Woning', consumerKey),
        new Selectable('Zakelijk bedrijf', 'Zakelijk bedrijf', corporateKey),
      ],

      serviceSelections: [
        new Selectable(
          'Kunststof kozijnen',
          'Kunststof kozijnen',
          'Kunststof kozijnen',
          {
            consumer: windowFramesPlasticConsumerBE,
            corporate: windowFramesPlasticCorporateBE,
          },
        ),
        new Selectable(
          'Houten kozijnen',
          'Houten kozijnen',
          'Houten kozijnen',
          {
            consumer: windowFramesWoodConsumerBE,
            corporate: windowFramesWoodCorporateBE,
          },
        ),
        new Selectable(
          'Aluminium kozijnen',
          'Aluminium kozijnen',
          'Aluminium kozijnen',
          {
            consumer: windowFramesAluminiumConsumerBE,
            corporate: windowFramesAluminiumCorporateBE,
          },
        ),
      ],
    }
  },
}
</script>
